@import url("https://fonts.cdnfonts.com/css/metropolis-2");


.main {
	font-family: 'Metropolis', sans-serif;
}

.mr-2{
	margin-right: .75rem;
}


.btn-primary{
	background: linear-gradient(180deg, #00FBFF 0%, #10DCE8 99.99%, #09C9E3 100%);
	box-shadow: 0px 2px 20px -10px rgba(29, 255, 241, 0.24);
	border-radius: 8px;
	font-weight: 600 !important;
	border: none;
	font-size: 1.1rem;
}

/* Global CSS */
.fw-500{
	font-weight: 500;
}
.text-primary{
	color: #0A0433 !important;
}
.text-lightgray{
	color: #777580 !important;
}
.text-lightgrey{
	color: #AEADB8;
}
.text-gradient{
    background: linear-gradient(90deg, #40BAFF 1.75%, #687FFF 36.04%, #A953FF 68.27%, #FB25FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}



.partner-header-dp img{
	backdrop-filter: blur(25.7537px);
	border-radius: 50%;
	width: 238.21px;
	height: 238.21px;
}

.partner-header-deatils h1{
	font-size: 3.5rem;
}

.partner-header-deatils p{
	font-size: 1rem;
	width: 80%;
	line-height: 1.6;

}
.background-gradient {
	padding: 2px;
	border-radius: 16px;
	background: linear-gradient(90deg, #40BAFF 1.75%, #687FFF 36.04%, #A953FF 68.27%, #FB25FF 100%);
	
}
.background-white{
	background: #fff;
	border-radius: 16px;
}
.money-card{
	background: linear-gradient(90deg, rgba(64, 186, 255, 0.05) 1.75%, rgba(104, 127, 255, 0.05) 36.04%, rgba(169, 83, 255, 0.05) 68.27%, rgba(251, 37, 255, 0.05) 100%);
	border: none;
	border-radius: 16px;
}
.money-card-number{
	font-weight: 800;
	font-size: 136px;
	line-height: 101%;
}

.project-card{
	background: #FFFFFF;
	border: 1px solid rgba(181, 178, 197, 0.24);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
	border-radius: 14px;
	margin: 1rem;
	width: 270px;
	max-width: 277px;
	
}

.project-card h4{
	margin-top: 2px;
	font-size:1rem;
	font-weight: 600;
	margin-top: .75rem;
	margin-bottom: .2rem;
}


.talent-hired-card{
	background: #FFFFFF;
	border-radius: 16.5375px;
	filter: drop-shadow(0px 7.23514px 45.478px rgba(0, 0, 0, 0.05));
}


@media screen and (min-width:769px) {
	.cta-small{
		display: none;
	}
	.cta-big{
		display: block;
	}
}

@media screen and (max-width:768px) {

	.partner-header-dp img {
		width: 150px;
		height: 150px;
	}

	.partner-header-deatils{
		text-align: center;
	}

	.partner-header-deatils h1 {
		margin-top: .5rem;
		font-family: 'Metropolis';
		font-style: normal;
		font-weight: 700;
		font-size: 2rem;
		line-height: 2;
		
	}
	.partner-header-deatils p{
		width: 90%;
		margin: auto;
		margin-top: 0 !important;
	}
	.social-icons{
		justify-content: center !important;
	}

	.money-card-number {
		font-weight: 800;
		font-size: 56px;
		line-height: 101%;
	}


	.cta-small {
		display: block;
	}

	.cta-big {
		display: none;
	}

	/* .project-card {
		width: 2100px;
	} */
} 


@media screen and (min-device-width: 769px) and (max-width:950px) {
	.partner-header-deatils{
		text-align: center;
		padding-top: 1rem;
	}
	.partner-header-deatils h1{
		font-size: 3rem;
		margin-bottom: .75rem;
	}
	.partner-header-deatils p {
		width: 90%;
		margin: auto;
		margin-top: 0 !important;
	}
	.social-icons {
		justify-content: center !important;
	}
	.money-card-number {
		font-weight: 800;
		font-size: 90px;
		line-height: 101%;
	}

	.project-card {
		width: 270px;
	}
}